import React,{useRef} from "react";
import emailjs from '@emailjs/browser';
import { useNavigate } from 'react-router-dom';

const Contact = () =>{
    var style = {
        height: 100
      }
      const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };
      const form = useRef();

      const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_cqodleh', 'template_wbb2591', form.current, 'B_jrGNUXQyQaAju3_')
          .then((result) => {
              console.log(result.text);
              handleSubmit();
          }, (error) => {
              console.log(error.text);
          });
      };
    
      const navigate = useNavigate();

      const handleSubmit = () => {
        navigate('/');
        alert('Your Query has been send contact you shortly Thanks!');
        scrollToTop();
      };
return(
<>
<div className="container-xxl mt-5 ">

        <div className="row">

        
  
    <div className="col-lg-6 text-center p-3 mt-5" data-wow-delay="0.5s">
                        <h3 >If You Have Any Query, Please Contact Us</h3>
                        <p className="mb-2 p11">Where client satisfaction is our priority. Feel free to reach out to us for any inquiries regarding software proposals or detailed information about our Real Estate ERP and other modules. Our dedicated Sales Team is here to assist you, ensuring a seamless experience as you explore Real Estate Tailored & Customized Software solutions for your Project's business needs. 
Contact us today, and let's embark on a journey to elevate your software experience together.</p>
</div>
<div className="col-lg-6 p-3  " data-wow-delay="0.5s">
<form ref={form} onSubmit={sendEmail}  id="email">

<div className="ccc">
<div className="w-100 mm">
<label for="fname">First Name</label><br/>
<input className="w-100" type="text" id="user_name" name="user_name" placeholder="Your name.." required/>
</div>
<div className="w-100 mm">
<label for="lname">Last Name</label><br/>
<input className="w-100"  type="text" id="user_lname" name="user_lname" placeholder="Your last name.." required/>
</div>
</div>
<div className="ccc">
<div className="w-100 mm">
<label for="fname">Mobile No</label><br/>
<input className="w-100" type="number" id="user_number" name="user_number" placeholder="+1" required/>
</div>
<div  className="w-100 mm">
<label for="lname">Email</label><br/>
<input className="w-100" type="text" id="user_email" name="user_email" placeholder="info@ex.com" required/>

</div>
</div>
<div className="ccc">
<div className="w-100">
<label for="country">Country</label><br/>
<select className="w-100 mm"  id="user_country" name="user_country" required><br/>
  <option value="australia">Australia</option>
  <option value="canada">Canada</option>
  <option value="usa">USA</option>
</select>
</div></div>
<div className="ccc">
  <div className="w-100 mm">
<label for="subject">Message</label><br/>
<textarea className="w-100" id="message" name="message" placeholder="Write something.." style={style} required></textarea><br/>
<div className="text-center">
<input type="submit"  value="Submit"/>
</div></div>
</div>
</form>
</div>

<iframe className=" w-100 h-200"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3401.365811882959!2d74.34099181079604!3d31.51411134734438!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x690a0188864e0af9%3A0x254b6898960ecf36!2sQuaid%20Soft%20%C2%AE%20%7C%20A%20Real%20Estate%20Software%20-%20Housing%20Scheme%20Software%20Company%20in%20Lahore%20Pakistan!5e0!3m2!1sen!2s!4v1701072931173!5m2!1sen!2s"
                            frameborder="0" height={400} title="email" allowfullscreen="" aria-hidden="false"
                            tabindex="0"></iframe>

</div>
</div>
    

</>

); 
}
export default Contact;