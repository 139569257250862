import React,{useEffect} from "react";
import img3 from "./CSS/MkAbout.jpeg";
import img4 from "./CSS/MkAbout1.jpeg";
import 'aos/dist/aos.css';
import AOS from 'aos';

const About = () =>{
    useEffect(() => {
        AOS.init();
      }, [])
    const myStyle = {
        marginBottom: '25%',
      };
      const myStyle2 = {
        position: 'absolute',
        top: '25%',
        left: '25%',
      };
return(
<>
<div class="container-xxl py-0 my-0">  
                <div class="row g-5">
                    <div class="col-lg-5 col-md-6 col-sm-12">
                        <div class="h-100 position-relative" >
                            <img src={img3}  class="img-fluid w-75 rounded" alt="" style={myStyle} data-aos="zoom-in" data-aos-delay="100"/>
                            <div class="position-absolute w-75"  style={myStyle2}>
                                <img src={img4} class="img-fluid w-100 rounded" alt="" data-aos="zoom-in" data-aos-delay="100"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-6 col-sm-12 " data-aos="zoom-in" data-aos-delay="500">
                        <h5 class="text-primary">About Us</h5>
                        <h1 class="mb-4">Mk Solutions and Services</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed efficitur quis purus ut interdum. Pellentesque aliquam dolor eget urna ultricies tincidunt. Nam volutpat libero sit amet leo cursus, ac viverra eros tristique. Morbi quis quam mi. Cras vel gravida eros. Proin scelerisque quam nec elementum viverra. Suspendisse viverra hendrerit diam in tempus. Etiam gravida justo nec erat vestibulum, et malesuada augue laoreet.</p>
                        <p class="mb-4">Pellentesque aliquam dolor eget urna ultricies tincidunt. Nam volutpat libero sit amet leo cursus, ac viverra eros tristique. Morbi quis quam mi. Cras vel gravida eros. Proin scelerisque quam nec elementum viverra. Suspendisse viverra hendrerit diam in tempus.</p>                    </div>
                </div>
            </div>  
</>


);

}
export default About;