import React,{useEffect} from "react";
import "./Home.css"
import About1 from "../MainPagePart/About1";
import Solution1 from "../MainPagePart/Solution1";
import Callaction from "../MainPagePart/Callaction1";
import Services1 from "../MainPagePart/Services1";
import Count from "../MainPagePart/Counts";
import 'aos/dist/aos.css';
import AOS from 'aos';
import Contact from "../MainPagePart/Contact";
import Typewriter from "../Header_Footer/Typewriter";
import Traning1 from "../MainPagePart/Traning1";
import CEO1 from "./Team/CEO";
const Home = () =>{
    useEffect(() => {
        AOS.init();
      }, [])
      
return(
<>

         <div id="hero" className="d-flex container-fluid align-items-center justify-content-center ">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-8 col-lg-8 ">
          <h1  data-aos="fade-up"
     data-aos-duration="2000" ><Typewriter text="Powerful Engineering Solutions With MK"  delay={50} /><span>.</span></h1>
     
          <h2  data-aos="zoom-in-up"><Typewriter text="MK Engineering: Pioneering Innovation, Precision, and Sustainable Solutions for a Future-Ready World"  delay={100}/> <b>..</b></h2>
        </div>
      </div>
    </div>
  </div>
  
  <div className="container-xxl">
  <div className="row p-4 justify-content-center ">
    <div className=" text-center">
    <h1 data-aos="fade-up">About Us</h1>
    </div>
  <About1/>
    </div>
    </div>
    <div className="container-xxl">
  <div className="row p-4 justify-content-center ">
<CEO1/>
    </div>
    </div>



    <div className="container-xxl ">
  <div className="row p-4 justify-content-center " >
    <div className=" py-2 text-center headerbd1" data-aos="fade-up">
    <h1 className="text-white"  >Solutions</h1>
    </div>
    <div class=" container py-4 my-0">
            <div class="container pt-4">
                <div class="row g-5">
                <Solution1/>
    </div></div></div>
    </div>
    </div>

    <div className="container-xxl ">
  <div className="row p-4 justify-content-center" >
    <div className=" py-2 text-center headerbd1 " data-aos="fade-up"> 
    <h1 className="text-white">Services</h1>
    </div>
    <div class=" container py-4 my-0">
            <div class="container pt-4">
                <div class="row g-5">       
    <Services1/>
    </div></div></div>
    </div>
    </div>

    <div className="container-xxl ">
  <div className="row p-4 justify-content-center ">
    <div className=" text-center">
    </div>
    <Callaction/>
    </div>
    </div>

    <div className="container-xxl py-2">
  <div className="row p-4 justify-content-center " >
  <div className=" py-2 text-center headerbd1 " data-aos="fade-up">
    <h1 className="text-white ">Engineering Training</h1>
    </div>
    
    <Traning1/>
    </div>
    </div>

    <div className="container-xxl">
  <div className="row p-4 justify-content-center ">
    <div className=" text-center">

    </div>
  <Count/>
    </div>
    </div>

    <div className="container-xxl  py-4">
  <div className="row p-4 justify-content-center ">
  <div className=" py-2 text-center headerbd1">
    <h2 className="text-white" data-aos="fade-up"><b>Contact Us</b></h2>
    </div>
  <Contact/>
    </div>
    </div>

           

                       

                        




</>

);

}
export default Home;