import React from "react";
import "../style/Teraning1.css";
import { Link } from "react-router-dom";
import img1 from "../style/img/project-1.jpg";
import img2 from "../style/img/project-2.jpg";
import img3 from "../style/img/project-3.jpg";
import img4 from "../style/img/project-4.jpg";
import img5 from "../style/img/project-5.jpg";
import img6 from "../style/img/project-6.jpg";

const Teraning1 = () => {
   
    
    return(
<>
<div class="container-xxl py-3">
 <div class="row row-cols-1 row-cols-md-3 g-4 text-center">
  <div class="col-lg-3 " data-aos="zoom-in-up">
    <div class="card">
      <img src={img1} class="card-img-top" alt="..."/>
      <div class="card-body">
       <Link className="conbtt1  text-white" to="#">Apply Now</Link>
        <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
      </div>
    </div>
  </div>
  <div class="col-lg-3 " data-aos="zoom-in-up">
    <div class="card">
      <img src={img2} class="card-img-top" alt="..."/>
      <div class="card-body">
      <Link className="conbtt1  text-white" to="#">Apply Now</Link>
        <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
      </div>
    </div>
  </div>
  <div class="col-lg-3 " data-aos="zoom-in-up">
    <div class="card">
      <img src={img3} class="card-img-top" alt="..."/>
      <div class="card-body">
      <Link className="conbtt1  text-white" to="#">Apply Now</Link>
        <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content.</p>
      </div>
    </div>
  </div>
  <div class="col-lg-3 " data-aos="zoom-in-up">
    <div class="card">
      <img src={img4} class="card-img-top" alt="..."/>
      <div class="card-body">
      <Link className="conbtt1  text-white" to="#">Apply Now</Link>
        <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content.</p>
      </div>
    </div>
  </div>
  <div class="col-lg-3 " data-aos="zoom-in-up">
    <div class="card">
      <img src={img5} class="card-img-top" alt="..."/>
      <div class="card-body">
      <Link className="conbtt1  text-white" to="#">Apply Now</Link>
        <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content.</p>
      </div>
    </div>
  </div>
  <div class="col-lg-3 " data-aos="zoom-in-up">
    <div class="card">
      <img src={img6} class="card-img-top" alt="..."/>
      <div class="card-body">
      <Link className="conbtt1  text-white" to="#">Apply Now</Link>
        <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content.</p>
      </div>
    </div>
  </div>
  <div class="col-lg-3 " data-aos="zoom-in-up">
    <div class="card">
      <img src={img1} class="card-img-top" alt="..."/>
      <div class="card-body">
      <Link className="conbtt1  text-white" to="#">Apply Now</Link>
        <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content.</p>
      </div>
    </div>
  </div>
  <div class="col-lg-3 " data-aos="zoom-in-up">
    <div class="card">
      <img src={img2} class="card-img-top" alt="..."/>
      <div class="card-body">
      <Link className="conbtt1  text-white" to="#">Apply Now</Link>
        <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content.</p>
      </div>
    </div>
  </div>

</div>

  </div>        

 

</>
    );
}
export default Teraning1;