import React from "react";
import Teraning1 from "../MainPagePart/Traning1";

const Traning =() =>{
    return(
<>
 <div className="breadcrumbs d-flex align-items-center headerbd">
      <div className="container position-relative d-flex flex-column align-items-center">
      <div className=" text-center text-white">
    <h2>Engineering Trainings</h2>
    </div>
      </div>
    </div>
    
<div class="container-fluid py-5 my-0">
            <div class="container pt-4">
                <div class="row g-5">
<Teraning1/>

</div></div></div>

</>
    );
}
export default Traning;