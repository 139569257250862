import React from "react";
import "../style/solutions.css";
import img1 from "../style/img/service-1.jpg";
import img2 from "../style/img/service-2.jpg";
import img3 from "../style/img/service-3.jpg";

const Solutions = () =>{
  
return(
<>
    <div class="container-xxl mt-4">
       
            <div class="row gy-5 gx-4">
                <div class="col-md-4 col-lg-3 " data-aos="zoom-in" data-aos-delay="100">
                    <div class="service-item">
                        <img class="img-fluid" src={img1} alt=""/>
                        <div class="service-img">
                            <img class="img-fluid" src={img1} alt=""/>
                        </div>
                        <div class="service-detail">
                            <div class="service-title">
                                <hr class="w-25"/>
                                <h3 class="mb-0">Civil & Gas Engineering</h3>
                                <hr class="w-25"/>
                            </div>
                            <div class="service-text">
                                <p class="text-white mb-0">Erat ipsum justo amet duo et elitr dolor, est duo duo eos
                                    lorem sed diam stet diam sed stet.</p>
                            </div>
                        </div>
                        <a class="btn btn-light" href="">Read More</a>
                    </div>
                </div>
                <div class="col-md-4 col-lg-3" data-aos="zoom-in" data-aos-delay="150">
                    <div class="service-item">
                        <img class="img-fluid" src={img2} alt=""/>
                        <div class="service-img">
                            <img class="img-fluid" src={img2} alt=""/>
                        </div>
                        <div class="service-detail">
                            <div class="service-title">
                                <hr class="w-25"/>
                                <h3 class="mb-0">Power & Energy Engineering</h3>
                                <hr class="w-25"/>
                            </div>
                            <div class="service-text">
                                <p class="text-white mb-0">Erat ipsum justo amet duo et elitr dolor, est duo duo eos
                                    lorem sed diam stet diam sed stet.</p>
                            </div>
                        </div>
                        <a class="btn btn-light" href="">Read More</a>
                    </div>
                </div>
                <div class="col-md-4 col-lg-3" data-aos="zoom-in" data-aos-delay="200">
                    <div class="service-item">
                        <img class="img-fluid" src={img3} alt=""/>
                        <div class="service-img">
                            <img class="img-fluid" src={img3} alt=""/>
                        </div>
                        <div class="service-detail">
                            <div class="service-title">
                                <hr class="w-25"/>
                                <h3 class="mb-0">Plumbing & Water Treatment</h3>
                                <hr class="w-25"/>
                            </div>
                            <div class="service-text">
                                <p class="text-white mb-0">Erat ipsum justo amet duo et elitr dolor, est duo duo eos
                                    lorem sed diam stet diam sed stet.</p>
                            </div>
                        </div>
                        <a class="btn btn-light" href="">Read More</a>
                    </div>
                </div>
                <div class="col-md-4 col-lg-3" data-aos="zoom-in" data-aos-delay="200">
                    <div class="service-item">
                        <img class="img-fluid" src={img3} alt=""/>
                        <div class="service-img">
                            <img class="img-fluid" src={img3} alt=""/>
                        </div>
                        <div class="service-detail">
                            <div class="service-title">
                                <hr class="w-25"/>
                                <h3 class="mb-0">Plumbing & Water Treatment</h3>
                                <hr class="w-25"/>
                            </div>
                            <div class="service-text">
                                <p class="text-white mb-0">Erat ipsum justo amet duo et elitr dolor, est duo duo eos
                                    lorem sed diam stet diam sed stet.</p>
                            </div>
                        </div>
                        <a class="btn btn-light" href="">Read More</a>
                    </div>
                </div>
            </div>
            <div class="row gy-5 py-5 gx-4">
                <div class="col-md-6 col-lg-3" data-aos="zoom-in" data-aos-delay="100">
                    <div class="service-item">
                        <img class="img-fluid" src={img1} alt=""/>
                        <div class="service-img">
                            <img class="img-fluid" src={img1} alt=""/>
                        </div>
                        <div class="service-detail">
                            <div class="service-title">
                                <hr class="w-25"/>
                                <h3 class="mb-0">Civil & Gas Engineering</h3>
                                <hr class="w-25"/>
                            </div>
                            <div class="service-text">
                                <p class="text-white mb-0">Erat ipsum justo amet duo et elitr dolor, est duo duo eos
                                    lorem sed diam stet diam sed stet.</p>
                            </div>
                        </div>
                        <a class="btn btn-light" href="">Read More</a>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3 " data-aos="zoom-in" data-aos-delay="150">
                    <div class="service-item">
                        <img class="img-fluid" src={img2} alt=""/>
                        <div class="service-img">
                            <img class="img-fluid" src={img2} alt=""/>
                        </div>
                        <div class="service-detail">
                            <div class="service-title">
                                <hr class="w-25"/>
                                <h3 class="mb-0">Power & Energy Engineering</h3>
                                <hr class="w-25"/>
                            </div>
                            <div class="service-text">
                                <p class="text-white mb-0">Erat ipsum justo amet duo et elitr dolor, est duo duo eos
                                    lorem sed diam stet diam sed stet.</p>
                            </div>
                        </div>
                        <a class="btn btn-light" href="">Read More</a>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3" data-aos="zoom-in" data-aos-delay="200">
                    <div class="service-item">
                        <img class="img-fluid" src={img3} alt=""/>
                        <div class="service-img">
                            <img class="img-fluid" src={img3} alt=""/>
                        </div>
                        <div class="service-detail">
                            <div class="service-title">
                                <hr class="w-25"/>
                                <h3 class="mb-0">Plumbing & Water Treatment</h3>
                                <hr class="w-25"/>
                            </div>
                            <div class="service-text">
                                <p class="text-white mb-0">Erat ipsum justo amet duo et elitr dolor, est duo duo eos
                                    lorem sed diam stet diam sed stet.</p>
                            </div>
                        </div>
                        <a class="btn btn-light" href="">Read More</a>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3" data-aos="zoom-in" data-aos-delay="200">
                    <div class="service-item">
                        <img class="img-fluid" src={img3} alt=""/>
                        <div class="service-img">
                            <img class="img-fluid" src={img3} alt=""/>
                        </div>
                        <div class="service-detail">
                            <div class="service-title">
                                <hr class="w-25"/>
                                <h3 class="mb-0">Plumbing & Water Treatment</h3>
                                <hr class="w-25"/>
                            </div>
                            <div class="service-text">
                                <p class="text-white mb-0">Erat ipsum justo amet duo et elitr dolor, est duo duo eos
                                    lorem sed diam stet diam sed stet.</p>
                            </div>
                        </div>
                        <a class="btn btn-light" href="">Read More</a>
                    </div>
                </div>
            </div>
        </div>
 

</>

);

}
export default Solutions;