import React from "react";
import { Link } from "react-router-dom";
import CEO from "./img/CEO1.png";
import CEO1 from "./img/CEO2.png";
import Team1 from "./img/Team1.jpg";
import Team2 from "./img/Team2.jpg";
import Team3 from "./img/Team3.jpg";
import "./Team.css"
const Teams = () => {
    const textStyle = {
        color: 'orange',
        fontSize: 'small',
      };
return(
<>
<div className="breadcrumbs d-flex align-items-center headerbd">
      <div className="container position-relative d-flex flex-column align-items-center" >
      <div className=" text-center text-white">
        <h2>Team</h2>
        </div>
      </div>
    </div>  
    <div class="container-xxl py-4 " >

            <div class="row g-0 team-items justify-content-center">
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div class="team-item position-relative">
                        <div class="position-relative">
                            <img class="img-fluid" src={Team1} alt=""/>
                            <div class="team-social text-center">
           <h5 style={{ color: '#ffffff' }}>Mazhar Khan<br/><span style={textStyle}>System Analyst</span></h5> 
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
                    <div class="team-item position-relative">
                        <div class="position-relative">
                            <img class="img-fluid" src={Team2} alt=""/>
                            <div class="team-social text-center">
                         <h5 style={{ color: '#ffffff' }}>Mazhar Khan <br/><span style={textStyle}>General Manager (KPK)</span></h5> 
                            </div>
                        </div>
                       
                    </div>
                </div>


                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div class="team-item position-relative">
                        <div class="position-relative">
                            <img class="img-fluid" src={Team2} alt=""/>
                            <div class="team-social text-center">
                                <h5 style={{ color: '#ffffff' }}>Mazhar Khan <br/><span style={textStyle}>General Manager Sales (KSA)</span></h5> 
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                    <div class="team-item position-relative">
                        <div class="position-relative">
                            <img class="img-fluid" src={Team2} alt="sarwat-raheem(quaidsoft)"/>
                            <div class="team-social text-center">
                                <h5 style={{ color: '#ffffff' }}>Mazhar Khan<br/><span style={textStyle}>General Manager Sales (Punjab)</span></h5>    
                            </div>
                        </div>
                    </div>
                </div>
            
            </div>

            <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '500px' }}>
                <h6 class="text-primary text-uppercase mb-2"></h6>
                <h1 class="display-6 mb-4"></h1>
            </div>
            <div class="row g-0 team-items justify-content-center">
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div class="team-item position-relative">
                        <div class="position-relative">
                            <img class="img-fluid" src={Team2} alt=""/>
                            <div class="team-social text-center">
                                <h5 style={{ color: '#ffffff' }}>Mazhar Khan<br/><span style={textStyle}>Software / Web Developer</span></h5>    
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div class="team-item position-relative">
                        <div class="position-relative">
                            <img class="img-fluid" src={Team2} alt=""/>
                            <div class="team-social text-center">
                                <h5 style={{ color: '#ffffff' }}>Mazhar Khan<br/><span style={textStyle}>Software Engineer</span></h5> 
                            </div>
                        </div>
                    </div>
                </div>


                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div class="team-item position-relative">
                        <div class="position-relative">
                            <img class="img-fluid" src={Team3} alt=""/>
                            <div class="team-social text-center">
                                <h5 style={{ color: '#ffffff' }}>Sarwat Raheem<br/><span style={textStyle}>Web Developer/SEO Expert</span></h5> 
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 wow fadeInUp" style={{borderRadius: '100px'}} data-wow-delay="0.7s">
                    <div class="team-item position-relative">
                        <div class="position-relative">
                            <img class="img-fluid" src={Team3} alt=""/>
                            <div class="team-social text-center">
                                <h5 style={{ color: '#ffffff' }}>Sarwat Raheem<br/><span style={textStyle}>Mobile App Developer</span></h5> 
                            </div>
                        </div>
                    </div>
                </div>
            
            </div>



    </div>
    
</>
);
}
export default Teams;