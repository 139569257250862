import React from "react";
import Contact from "../MainPagePart/Contact";

const Contactus = () =>{
  var style = {
    height: 100
  }
return(
<>
<div className="breadcrumbs d-flex align-items-center headerbd">
      <div className="container position-relative d-flex flex-column align-items-center">
      <div className=" text-center text-white">
    <h2>Contact Us</h2>
    </div>
      </div>
    </div>
    
<div class="container-xxl py-2 my-0">
                <div class="row g-5 p-4 pt-2">
<Contact/>
</div></div>

</>

);

}
export default Contactus;