import React from "react";
const Sitemap = () =>{
return(
<>
<div  className="breadcrumbs d-flex align-items-center headerbd">
      <div className="container position-relative d-flex flex-column align-items-center" >
      <div className=" text-center text-white">
        <h2>SiteMap</h2>
        </div>
      </div>
    </div>
    <div className='container-xxl'>
    <div className='text-center row p-4 w-100'>
    <h3>Comming Soon</h3>
   
   </div>
    </div>
</>
);
}
export default Sitemap;