import React from "react";
import { Link } from "react-router-dom";
import "./CSS/Parts.css";
const Services1 = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
    return(
<>
    <div id="services" className="services">
      <div className="container-xxl" data-aos="fade-up">
        <div className="row">
          <div className="col-lg-3 col-md-4 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
            <div className="icon-box">
              <div className="icon"><i className="bi bi-gear"></i></div>
              <h4><Link className="a1a1" to="/">Mailing</Link></h4>
              <p>Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
            <div className="icon-box">
              <div className="icon"><i className="bi bi-gear"></i></div>
              <h4><Link className="a1a1" to="">Welding</Link></h4>
              <p>a fabrication process whereby two or more parts are fused together by means of heat, pressure or both forming a join as the parts cool.</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 d-flex align-items-stretch mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay="300">
            <div className="icon-box">
              <div className="icon"><i className="bi bi-gear"></i></div>
              <h4><Link className="a1a1" to="">Assembling</Link></h4>
              <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 d-flex align-items-stretch mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay="300">
            <div className="icon-box">
              <div className="icon"><i className="bi bi-gear"></i></div>
              <h4><Link className="a1a1" to="">Metrology</Link></h4>
              <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="200">
            <div className="icon-box">
              <div className="icon"><i className="bi bi-gear"></i></div>
              <h4><Link className="a1a1" to="">Methodology</Link></h4>
              <p>n its most common sense, methodology is the study of research methods. However, the term can also refer to the methods themselves or to the philosophical.</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="200">
            <div className="icon-box">
              <div className="icon"><i className="bi bi-gear"></i></div>
              <h4><Link className="a1a1" to="">Nuclear Services</Link></h4>
              <p>n its most common sense, methodology is the study of research methods. However, the term can also refer to the methods themselves or to the philosophical.</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="300">
            <div className="icon-box">
              <div className="icon"><i className="bi bi-gear"></i></div>
              <h4><Link className="a1a1" to="">Instrumental Action</Link></h4>
              <p>Modi nostrum vel laborum. Porro fugit error sit minus sapiente sit aspernatur</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="200">
            <div className="icon-box">
              <div className="icon"><i className="bi bi-gear"></i></div>
              <h4><Link className="a1a1" to="">Hyduculus</Link></h4>
              <p>n its most common sense, methodology is the study of research methods. However, the term can also refer to the methods themselves or to the philosophical.</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="200">
            <div className="icon-box">
              <div className="icon"><i className="bi bi-gear"></i></div>
              <h4><Link className="a1a1" onClick={scrollToTop} to="/Software-Development">Software Develpoment</Link></h4>
              <p>n its most common sense, methodology is the study of research methods. However, the term can also refer to the methods themselves or to the philosophical.</p>
            </div>
          </div>
        </div>

      </div>
    </div>


</>
    );
}
export default Services1;