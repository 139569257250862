import React from "react";
import { Link } from "react-router-dom";
import "./Social.css"
const Social = ()=>{
return(
<>  
<div class="icon-bar">
<Link to="https://api.whatsapp.com/send?phone=+923214812006&text=Hello%21%20QuaidSoft" class="whatsapp a1" target="_blank"><i class="fa fa-whatsapp"></i></Link> 
  <Link to="https://www.facebook.com/quaidsofttech#" target="_blank" class="facebook a1"><i class="fa fa-facebook"></i></Link>
      <Link to="https://twitter.com/QuaidSoft" class="twitter a1" target="_blank"><i class="fa fa-twitter"></i></Link> 
<Link to="https://www.instagram.com/quaidsoft/" class="Instragram a1" target="_blank"><i class="fa fa-instagram"></i></Link>  
  <Link to="https://www.linkedin.com/company/quaid-soft/about/" class="linkedin a1" target="_blank"><i class="fa fa-linkedin"></i></Link>
  <Link to="https://youtube.com/@quaidsoft" class="youtube a1" target="_blank"><i class="bi bi-youtube"></i></Link> 
</div>

</>

);

}
export default Social;