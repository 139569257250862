import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css"

const Footer = () =>{
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };
  return(
<>

<div  className="container-xxl text-light link-white footer my-2 mb-0 py-5 bg-dark"> 
            <div className="row p-4 g-6">
            <ul className="col-lg-3 col-md-6 list-unstyled  ">
                   <li> <h3 className="text-light mb-2">Quick Links</h3></li>
                   <li>  <Link  onClick={scrollToTop} className="footer-link" to="/"  >Home</Link></li>
                   <li> <Link  onClick={scrollToTop} className="footer-link" to="/About"  >About Us</Link></li>
                   <li>   <Link  onClick={scrollToTop} className="footer-link" to="/Services"  >Services</Link></li>
                   <li>  <Link  onClick={scrollToTop} className="footer-link" to="/Solutions"  >Solutions</Link></li>
                   <li>  <Link  onClick={scrollToTop} className="footer-link" to="/Traning"  >Training</Link></li>
                   <li>  <Link  onClick={scrollToTop} className="footer-link" to="/Teams"  >Team</Link></li>
                   <li>  <Link  onClick={scrollToTop} className="footer-link" to="/Contactus" >Contact Us</Link></li>
                   <li>  <Link  onClick={scrollToTop} className="footer-link" to="/Sitemap" >Site Map</Link></li>
                 
                </ul>
                <ul className="col-lg-3 col-md-6 list-unstyled ">
                <li><h3 className="text-light mb-2">Solutions</h3></li>
                    <li> <Link  onClick={scrollToTop} className="footer-link" to="#">Popular Links</Link></li>
                    <li><Link  onClick={scrollToTop} className="footer-link"to="#">Popular Links</Link></li>
                    <li><Link  onClick={scrollToTop} className="footer-link" to="#">Popular Links</Link></li>
                    <li><Link  onClick={scrollToTop} className="footer-link" to="#">Popular Links</Link></li>
                    <li><Link  onClick={scrollToTop} className="footer-link" to="#">Popular Links</Link></li>
                    <li><Link  onClick={scrollToTop} className="footer-link" to="#">Popular Links</Link></li>
                    <li><Link  onClick={scrollToTop} className="footer-link" to="#">Popular Links</Link></li>
                    <li><Link  onClick={scrollToTop} className="footer-link" to="#">Popular Links</Link></li>
                </ul>
                <ul className="col-lg-3 col-md-6 list-unstyled ">
                <li><h3 className="text-light mb-2">Services</h3></li>
                    <li> <Link  onClick={scrollToTop} className="footer-link" to="#">Mailing</Link></li>
                    <li><Link  onClick={scrollToTop} className="footer-link"to="#">Welding</Link></li>
                    <li><Link  onClick={scrollToTop} className="footer-link" to="#">Assembling</Link></li>
                    <li><Link  onClick={scrollToTop} className="footer-link" to="#">Metrology</Link></li>
                    <li><Link  onClick={scrollToTop} className="footer-link" to="#">Methodology</Link></li>
                    <li><Link  onClick={scrollToTop} className="footer-link" to="#">Nuclear Services</Link></li>
                    <li><Link  onClick={scrollToTop} className="footer-link" to="#">Instrumental Action</Link></li>
                    <li><Link  onClick={scrollToTop} className="footer-link" to="#">Hyduculus</Link></li>
                    <li><Link  onClick={scrollToTop} className="footer-link" to="/Software-Development">Software Develpoment</Link></li>

                </ul>
                <ul className="col-lg-3 col-md-6 list-unstyled ">
                <li><h3 className="text-light mb-2">Training</h3></li>
                    <li> <Link  onClick={scrollToTop} className="footer-link" to="#">Popular Links</Link></li>
                    <li><Link  onClick={scrollToTop} className="footer-link"to="#">Popular Links</Link></li>
                    <li><Link  onClick={scrollToTop} className="footer-link" to="#">Popular Links</Link></li>
                    <li><Link  onClick={scrollToTop} className="footer-link" to="#">Popular Links</Link></li>
                    <li><Link  onClick={scrollToTop} className="footer-link" to="#">Popular Links</Link></li>
                    <li><Link  onClick={scrollToTop} className="footer-link" to="#">Popular Links</Link></li>
                    <li><Link  onClick={scrollToTop} className="footer-link" to="#">Popular Links</Link></li>
                    <li><Link  onClick={scrollToTop} className="footer-link" to="#">Popular Links</Link></li>
                </ul>
                <hr />
                <ul className="col-lg-3 col-md-6 list-unstyled  ">
                   <li> <h3 className="text-light mb-2">Contact Us</h3></li>
                  <li> <Link  className="footer-link" to="#" target="_blank" ><i className="fa fa-phone me-3"></i>+1 (704) 890-7110</Link></li>
                  <li> <Link  className="footer-link" to="#" target="_blank" ><i className="fa fa-phone me-3"></i>+1 (704) 890-7110</Link></li>
                  <li>  <Link href="mailto:youremail@example.com"  className="footer-link" to="#" target="_blank" ><i className="fa fa-envelope me-3"></i>mazhar.khan@bell.net</Link></li>
                   <li> <Link  className="footer-link" target="_blank" href="https://www.mksolutionsservices.com"><i className="fa fa-chrome me-3"></i>www.mksolutionsservices.com</Link></li>
                    <div className="d-flex pt-1">
                        <Link   className="btn btn-square btn-outline-light me-1" target="_blank"  to="#"><i className="bi bi-whatsapp"></i></Link>
                        <Link   className="btn btn-square btn-outline-light me-1" target="_blank"  to="#"><i className="fab fa-facebook-f"></i></Link>
                        <Link   className="btn btn-square btn-outline-light me-1"  target="_blank" to="#"><i className="fab fa-linkedin-in"></i></Link>
                        <Link   className="btn btn-square btn-outline-light me-1" target="_blank"  to="#"><i className="fab fa-instagram"></i></Link>
                        <Link  className="btn btn-square btn-outline-light me-1" target="_blank" to="#"><i className="fab fa-twitter"></i></Link>
                        <Link   className="btn btn-square btn-outline-light me-1" target="_blank"  to="#"><i className="fab fa-youtube"></i></Link>
                    
                    </div>
                
                </ul>
                <div class="col-lg-3 col-md-6">
                <h3 class="text-light mb-2">Address</h3>
                <div  style={{ display: "flex" }} >
                    <i class="fa fa-map-marker" style={{font: "large"}}></i>
                    <i style={{font: "large"} } >Mrs Smith 813 Howard Street Oswego NY 13126 USA</i>
                    </div>
                </div>  
           



                </div></div>
                <div id="hero1" class="container-xxl copyright text-light py-4  " >
        
            <div class="row">
                <div class="col-md-6 text-center text-md-start mb-3 mb-md-0">
                Copyright © 2023 Quaid Soft | MKSolutions&Services
                </div>
                <div class="col-md-6 text-center text-md-end">
                    Designed By  <Link className="text-white" target="_blank" to="https://www.quaidsoft.com">Quaidsoft</Link>
                </div>
            </div>
        
        </div>
                </>
);
}
export default Footer;