import React from "react";
import Solution1 from "../MainPagePart/Solution1";
const Solutions = () =>{


return(
<>

<div className="breadcrumbs d-flex align-items-center headerbd">
      <div className="container position-relative d-flex flex-column align-items-center" >
      <div className=" text-center text-white">
        <h2>Solutions</h2>
        </div>
      </div>
    </div>
    
<div class="container-xxl py-0 my-0">    
                <div class="row g-5">
<Solution1/>
</div></div>
</>

);

}
export default Solutions;