import React from 'react'

const Careers = () => {
  return (
    <>
    <div className="breadcrumbs d-flex align-items-center headerbd">
    <div className="container position-relative d-flex flex-column align-items-center">
    <div className=" text-center text-white">
  <h2>Career</h2>
  </div>
    </div>
  </div>
  
  <div className='container-xxl'>
    <div className='text-center row p-4 w-100'>
    <h3>Comming Soon</h3>
   
   </div>
    </div>
</>
  );
}
export default Careers;