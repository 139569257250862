import React from 'react'
import CEO2 from "./img/CEO1.png";
import CEO1 from "./img/CEO2.png";
import { Link } from 'react-router-dom'
const CEO = () => {
  return (
    <>
      <div class="container-xxl py-4 " >
        <div class=" wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '500px' }}>
            <h3 class="mb-3">Management Message</h3>
        </div>        
           <div class="row p-2 g-0 team-items justify-content-center">
               
               <div class=" col-md-6 wow fadeInUp p-2" data-wow-delay="0.1s">
                   <div class="position-relative ">  
                           <p class="mb-4 p11" >MKSolutionsServices is a leading provider of engineering solutions, offering a comprehensive range of services from design to prototyping. Our training programs are designed to meet diverse engineering needs, emphasizing hands-on experience and real-world applications. Courses are consistently updated to align with industry trends, ensuring participants acquire the latest skills. Experienced instructors, with a strong industry background, lead our programs, providing valuable insights and expertise. Our state-of-the-art facilities grant students access to the latest tools and technologies for a complete learning experience. We specialize in customized engineering solutions, collaborating closely with clients to effectively address their unique challenges. Networking opportunities are fostered through industry events and workshops, connecting professionals within the field. Certification programs enhance participants' employability, adding significant value to their resumes in the competitive engineering sector. Continuous improvement is a core principle, with regular assessments and updates to stay ahead in the dynamic engineering landscape. Explore MKSolutionsServices for industry-relevant training and innovative engineering solutions tailored to your specific needs.</p>
                   </div>
               </div>
               <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                   <div class="team-item position-relative">
                       <div class="position-relative">
                           <img class="img-fluid" src={CEO1} alt=""/>
                           <div class="team-social text-center">
                               <Link class="btn btn-square btn-outline-light me-1" target="_blank" href="https://www.facebook.com/mianazharhussain.pk"><i class="fab fa-facebook-f"></i></Link>
                               <Link class="btn btn-square btn-outline-light me-1" target="_blank" href="https://twitter.com/azharhussain_pk"><i class="fab fa-twitter"></i></Link>
                               <Link class="btn btn-square btn-outline-light me-1" target="_blank" href="https://www.instagram.com/azharhussain_pk/"><i class="fab fa-instagram"></i></Link>
                           </div>
                       </div>
                       <div class="bg-light text-center p-4">
                           <h5 class="mt-2">Mazhar Khan</h5>
                           <span>Managing Director</span>
                       </div>
                   </div>
               </div>
               <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
                   <div class="team-item position-relative">
                       <div class="position-relative">
                           <img class="img-fluid" src={CEO2} alt=""/>
                           <div class="team-social text-center">
                               <Link class="btn btn-square btn-outline-light me-1" target="_blank" href="https://www.facebook.com/yehchees"><i class="fab fa-facebook-f"></i></Link>
                               <Link class="btn btn-square btn-outline-light me-1" target="_blank" href="#"><i class="fab fa-twitter"></i></Link>
                               <Link class="btn btn-square btn-outline-light me-1" target="_blank" href="https://www.instagram.com/yehchees/?igsh=MWQ0bm11aGY3MGlyeg%3D%3D"><i class="fab fa-instagram"></i></Link>
                           </div>
                       </div>
                       <div class="bg-light text-center p-4">
                           <h5 class="mt-2">Mazhar Khan</h5>
                           <span>Chief Executive Officer</span>
                       </div>
                   </div>
               </div>
           
           </div>
       </div>
    </>
  )
}

export default CEO